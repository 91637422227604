import { Box, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Card, Layout, LinkButton } from "../../components/UI/Common";
import Grid from "@mui/material/Unstable_Grid2";
import { Update, updates } from "./updates";
import { Text1, Text2, Text4, Title4 } from "../../components/UI/Text";
import { TimeSince } from "./Time";
import styled from "styled-components";
import { BlobButton } from "../../components/UI/BlobButton";
import { Dispatch, SetStateAction, useState } from "react";
import { Size } from "../../config/theme.config";

export const LatestUpdates = (): JSX.Element => {
  const { t } = useTranslation();
  const updateSorted = updates.sort(function (a, b) {
    return b.id - a.id;
  });
  const [page, setPage] = useState<number>(updateSorted[0].id);
  const mostRecentUpdates = updateSorted.slice(0, 4);
  const olderUpdates = updateSorted.slice(4);
  return (
    <Layout>
      <Box display="flex" flexDirection="column" gap={6}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid xs={12} sm={8} lg={7}>
              <MainUpdate
                page={page}
                setPage={setPage}
                update={updateSorted[updates.length - page]}
              />
            </Grid>
            <Grid xs={12} sm={4} lg={5}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height="100%"
              >
                {mostRecentUpdates.map((update) => (
                  <PreviusUpdates key={update.id} page={page} setPage={setPage} update={update} />
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box marginBottom={8}>
          <Title4 mb={2} weight="bold">
            {t("pages.updates.olderUpdates")}
          </Title4>
          <Grid container spacing={2}>
            {olderUpdates.map((update) => (
              <Grid xs={12} sm={6} lg={4}>
                <PreviusUpdates key={update.id} page={page} setPage={setPage} update={update} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Layout>
  );
};

type UpdateProp = {
  update: Update;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
};
export const MainUpdate = ({ update, page, setPage }: UpdateProp): JSX.Element => {
  const { t, i18n } = useTranslation();
  const translate = i18n.resolvedLanguage === "sv" ? update.swedish : update.english;
  const pCount = translate.text.length;
  return (
    <MagnifiedCard secondary padding={2}>
      <Box
        sx={{
          marginBottom: {
            xs: "12px !important",
            md: "0px !important",
          },
        }}
      >
        {update.imageUrl && (
          <ImageContainer>
            <img alt="cover" src={update.imageUrl} />
          </ImageContainer>
        )}
        <Text4 mb={0.5}>{TimeSince(update.date)}</Text4>
        <Title4
          lineHeight={1}
          weight="bold"
          sx={{
            marginBottom: {
              xs: "8px !important",
              md: "16px !important",
            },
          }}
        >
          {translate.title}
        </Title4>
        {translate.text.map((p, i) => (
          <Text2
            sx={{
              marginBottom: {
                xs: i === pCount - 1 ? "12px !important" : "8px !important",
                md: "16px !important",
              },
            }}
          >
            {p}
          </Text2>
        ))}
        {translate.ctaButton && (
          <Box paddingTop={1} paddingBottom={1}>
            <Link href={translate.ctaButton.link}>
              <Box display="flex" justifyContent="center">
                <Text2 mb={0}>{translate.ctaButton.label}</Text2>
              </Box>
            </Link>
          </Box>
        )}
        {translate.list && (
          <>
            <Text2
              sx={{
                marginBottom: {
                  xs: "0px !important",
                  md: "12px !important",
                },
              }}
            >
              {translate.list.title}
            </Text2>
            <ul style={{ marginTop: "6px" }}>
              {translate.list.items.map((item) => (
                <li>
                  <Text2 mb={1}>{item}</Text2>
                </li>
              ))}
            </ul>
          </>
        )}
        {translate.footer && translate.footer.map((p, i) => <Text2 mb={0}>{p}</Text2>)}
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <LinkButton disabled={page === updates.length} onClick={() => setPage(page + 1)}>
          {t("common.previous")}
        </LinkButton>
        <LinkButton disabled={page === 1} onClick={() => setPage(page - 1)}>
          {t("common.next")}
        </LinkButton>
      </Box>
    </MagnifiedCard>
  );
};

export const PreviusUpdates = ({ update, page, setPage }: UpdateProp): JSX.Element => {
  const { t, i18n } = useTranslation();
  const translate = i18n.resolvedLanguage === "sv" ? update.swedish : update.english;

  return (
    <MiniatureCard secondary padding={0} style={{ opacity: page === update.id ? 0.7 : 1 }}>
      <Box flexGrow="1" padding={2}>
        <Text4 mb={0}>{TimeSince(update.date)}</Text4>
        <Ellipsis numOfLines={1}>
          <Text1 mb={0} weight="bold">
            {translate.title}
          </Text1>
        </Ellipsis>
        <Ellipsis numOfLines={3}>
          <Text4
            sx={{
              marginBottom: {
                xs: "4px !important",
                md: "8px !important",
              },
            }}
          >
            {translate.text[0]}
            {translate.text[1] ? (
              <>
                <br />
                <br /> {translate.text[1]}
              </>
            ) : (
              ""
            )}
          </Text4>
        </Ellipsis>
        {page === update.id ? (
          <Text4
            sx={{
              paddingBlock: "5.5px",
            }}
            mb={0}
            weight="bold"
          >
            {t("common.currentlyReading")}
          </Text4>
        ) : (
          <BlobButton
            onClick={() => setPage(update.id)}
            disabled={page === update.id}
            fontSize={1.5}
            py={0.5}
            px={1.5}
            text={t("common.readMore")}
          />
        )}
      </Box>
    </MiniatureCard>
  );
};

const MagnifiedCard = styled(Card)`
  min-height: 700px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media only screen and (max-width: ${Size.sm}px) {
    height: auto;
    min-height: 475px;
  }
`;
const MiniatureCard = styled(Card)`
  padding: 0;
  display: flex;
  flex-direction: row;
  border-radius: ${(props) => props.theme.borderRadius.small};
  @media only screen and (max-width: ${Size.sm}px) {
    margin-bottom: ${(props) => props.theme.spacer * 2}px;
  }
`;
const ImageContainer = styled.div`
  width: 100%;
  height: 300px;
  overflow: hidden;
  border-radius: ${(props) => props.theme.borderRadius.small};
  margin-bottom: ${(props) => props.theme.spacer}px;
  display: flex;
  align-items: center;
  img {
    width: 100%;
    height: auto;
    image-position: center;
  }
  @media only screen and (max-width: ${Size.xl}px) {
    height: auto;
  }
`;

const Ellipsis = styled.span<{ numOfLines: number }>`
  p {
    display: -webkit-box;
    -webkit-line-clamp: ${(props) => props.numOfLines};
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;
