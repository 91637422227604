import img1 from "../../assets/logo/logo-on-white.png";
import img2 from "../../assets/latestUpdates/img2.jpg";
import img3 from "../../assets/latestUpdates/img3.jpg";
import img4 from "../../assets/latestUpdates/id4.png";

type CTAButton = {
  label: string;
  link: string;
};
type List = {
  title: string;
  items: string[];
};

type Swedish = {
  title: string;
  text: string[];
  footer?: string[];
  list?: List;
  ctaButton?: CTAButton;
};

type English = {
  title: string;
  text: string[];
  footer?: string[];
  list?: List;
  ctaButton?: CTAButton;
};

export interface Update {
  id: number;
  date: string;
  imageUrl?: string;
  english: English;
  swedish: Swedish;
}

export const updates: Update[] = [
  {
    id: 1,
    date: "2022-11-09",
    imageUrl: img1,
    english: {
      title: "We are officially started",
      text: [
        "Today we are finally starting the work on one of our plots we bought to build our first school on. We are removing the weeds to be able to lay a secure ground to build the school on.",
        "This is a small process but a big step towards our mission.",
      ],
    },
    swedish: {
      title: "Vi är officiellt igång!",
      text: [
        "Idag startar vi äntligen igång vårt arbete på marken vi köpt i höstas. Vi rensar buskaget på marken för att senare kunna lägga en stabil grund att bygga skolan på.",
        "Det må vara en mindre process, men det är ett stort steg närmare vår vission.",
      ],
    },
  },
  {
    id: 2,
    date: "2022-11-22",
    imageUrl: img3,
    english: {
      title: "One step closer!",
      text: [
        "We’ve finally removed the weeds! Now we are currently in the process of removing the roots and flattening the ground so we can achieve a solid ground to build upon.",
      ],
    },
    swedish: {
      title: "Ett steg närmare!",
      text: [
        "Vi har äntligen tagit bort ogräset! Nu håller vi på att ta bort rötterna och platta till marken så att vi kan få en fast mark att bygga på.",
      ],
    },
  },
  {
    id: 3,
    date: "2022-11-25",
    imageUrl: img2,
    english: {
      title: "The ceremony",
      text: [
        "In Ghanaian culture, this ceremony is essential before starting the building process. Pouring of libation to our ancestors and gods to help us make everything successful.",
        "It’s just so beautiful!",
      ],
    },
    swedish: {
      title: "Ceremoni",
      text: [
        "I den Ghananska kulturen är denna ceremoni viktig att fullfölja innan byggprocessen påbörjas. Vi offrar en speciell dricka till våra förfäder och gudar för att hjälpa oss att göra allt framgångsrikt.",
        "Så vackert!.",
      ],
    },
  },
  {
    id: 4,
    date: "2022-12-10",
    imageUrl: img4,
    english: {
      title: "First draft of the building plan is ready!",
      text: [
        "The first draft is finally done. Keep in mind that the plan is to take it step by step. We will start with one classroom at a time so that in the future we have a complete school from nursery up to primary 6. ",
        "In the plan we have invluded a lunch hall, which is very uncommon for schools in Ghana. We want to make sure that the children have enough nutrition to be able to focus. Otherwise the children struggeling most with poverty wouldn't have the same chance as the rest. Which is what we want to change. ",
        "We have also included a soccer field and a playground i the middle. This way its easy for the teachers to look out for the children, while sitting in a shaded area.",
      ],
    },
    swedish: {
      title: "Första utkast av ritningen är klar!",
      text: [
        "Det första utkastet är äntligen klart. Ha i åtanke att planen är att bygga skolan steg för steg. Vi börjar med ett klassrum i taget så att vi i framtiden har en komplett skola. Skolan inkluderar förskola upp till klass 6 (lågstadiet).",
        "I planen har vi inkluderat en lunchhall, något som är mycket ovanligt för skolor i Ghana. Vi vill se till att barnen får tillräckligt med näring för att kunna fokusera. Annars skulle de barn som kämpar mest med fattigdom inte ha samma chans som de andra. Vilket är något vi vill förändra på.",
        "Vi har även inkluderat en fotbollsplan och en lekplats i mitten av skolan. På så sätt är det lätt för lärarna att hålla utkik efter barnen samtidigt som de sitter i ett skuggigt område.",
      ],
    },
  },
  {
    id: 5,
    date: "2023-09-30",
    english: {
      title: "Certified accountant wanted!",
      text: [
        "We're in the process of applying for a 90-account. To get our applicaiton approved is crucial to enable efficient and transparent fundraising. When applying for a 90-account it is required that we have a certified accountant in our board.",
        "If you're ready to make a meaningful difference and share your expertise for a good cause, or if you know someone who might be - don't hesitate to contact us!",
        "Our organization is completely non-profit. This means that we are looking for someone who is willing to contribute pro bono. Every little contribution counts as we strive to give the children of Ghana a better future.",
      ],
      list: {
        title: "Requirements",
        items: [
          "Authorized by the 'Swedish Auditors Inspectorate' as an accountant (auktoriserad revisor)",
          "Is resident in Sweden or in another state within the EEA",
          "Willing to get involved in board work (meetings, advice, etc.)",
        ],
      },
      ctaButton: {
        label: "Contact us",
        link: "https://classroomsforchange.org/contact",
      },
    },
    swedish: {
      title: "Auktoriserad revisor sökes!",
      text: [
        "Vi är i processen av att ansöka om ett 90-konto. Det här är något som är avgörande för att möjliggöra en effektiv och transparant insamling. För att ansöka om ett 90-konto, krävs det att vi har en auktoriserad revisor i vår styrelse.",
        "Om du är redo att göra en meningsfull skillnad och dela din expertis för en god sak, eller om du känner någon som kan vara det - tveka inte att kontakta oss!",
        "Vår organisation är helt ideell. Detta innebär att vi söker någon som är villig att bidra pro bono. Varje liten insats räknas - tillsmmans kan vi ge barnen i Ghana en bättre framtid!",
      ],
      list: {
        title: "Krav",
        items: [
          "Auktoriserad revisor",
          "Är bosatt i Sverige eller i en annan stat inom EES",
          "Villig att engagera sig i styrelsearbetet (möten, rådgivning, etc.)",
        ],
      },
      ctaButton: {
        label: "Kontakta oss",
        link: "https://classroomsforchange.org/contact",
      },
    },
  },
  {
    id: 6,
    date: "2023-12-16",
    english: {
      title: "Breaking Ground: Taking the Next Steps",
      text: [
        "I hope this update finds you well and filled with the same excitement and anticipation that I am experiencing.",
        "Last year, we were on the verge of breaking ground on the first classroom. However, we decided to pause the construction momentarily as we explored the possibility of acquiring the adjacent land plot. The idea was to secure a larger area, laying the foundation for future expansion.",
        "After careful consideration and reflection, I have come to the realization that sometimes the best way to plan for the future is to take bold steps in the present. While the prospect of a larger land plot held promise for the long term, the immediate need for a functional learning space for the children in the village cannot be overlooked.",
        "Therefore, I am thrilled to announce that we have decided to move forward with the construction of the first classroom using the materials we acquired last year. It is a momentous decision, and one that aligns with our commitment to making a tangible impact on the lives of these children as soon as possible.",
        "Stay tuned for updates as we embark on this exciting chapter in our mission to build a brighter future through education.",
        "With gratitude,",
      ],
      footer: ["Hadla Bergman,", "Founder, Classrooms for Change"],
    },
    swedish: {
      title: "Påbörjar Byggandet: Nästa Steg i Processen",
      text: [
        "Jag hoppas att denna uppdatering fyller dig med samma spänning och förväntan som jag upplever.",
        "Förra året befann vi oss nära startpunkten för att påbörja konstruktionen av det allra första klassrummet. Dock beslutade vi att pausa byggnationen tillfälligt medan vi undersökte möjligheten att förvärva den intilliggande marken. Tanken var att säkra ett större område, som lägger grunden för framtida expansion.",
        "Efter noggrant övervägande och reflektion har jag insett att ibland är det bästa sättet att planera för framtiden att ta djärva steg i nuet. Medan utsikten till en större markplot höll löften för långsiktig framgång får vi inte förlora synen på det omedelbara behovet av en fungerande lärmiljö för barnen i byn.",
        "Därför är jag glada att meddela att vi har beslutat att gå vidare med konstruktionen av det första klassrummet med de material vi förvärvade förra året. Det är ett betydelsefullt beslut som överensstämmer med vårt åtagande att göra en påtaglig skillnad i dessa barns liv så snart som möjligt.",
        "Håll er uppdaterade för nyheter när vi ger oss in i detta spännande kapitel i vårt uppdrag att bygga en ljusare framtid genom utbildning.",
        "Med tacksamhet,",
      ],
      footer: ["Hadla Bergman,", "Grundare, Classrooms for Change"],
    },
  },
  {
    id: 7,
    date: "2023-12-31",
    english: {
      title: "Where does donations go?",
      text: [
        "Last year marked the beginning of our mission to provide free education to those who need it most.",
        "Now, at a pivotal point, we're completing our first classroom!",
        "While last year's donations fell short, most of the money could be used to cost for legal paper work to securing our land. The rest went to building material, used today.",
        "Help us transform our vision into reality.",
        "To read specificaitons of what is needed to build a classroom, check out the link!",
      ],
      ctaButton: {
        label: "Donate",
        link: "https://gofund.me/e8234334",
      },
    },
    swedish: {
      title: "Vad behöver vi finansiera?",
      text: [
        "Förra året markerade början av vår mission att erbjuda en gratis utbildning till barn i byarna.",
        "Tyvärr räckte inte finansieringen från förra året för att färdigställa det första klassrummet. Största delen användes för att täcka kostnader för juridiska kostnader för att säkra ägarskap av vår mark. Resterande gick till byggmaterial som används idag.",
        "Planen kommande veckor är att bygga det första klassrummet (30x20 fot).",
        "För att läsa mer om specifikationerna för vad som krävs för att bygga ett klassrum, klicka på länken!",
      ],
      ctaButton: {
        label: "Donera",
        link: "https://gofund.me/e8234334",
      },
    },
  },
];
