import CLogoOnYellow from "../assets/logo/c-logo-on-yellow.png";
import logoOnYellow from "../assets/logo/logo-on-yellow.png";
import CLogoOnBlack from "../assets/logo/c-logo-on-black.png";
import logoOnBlack from "../assets/logo/logo-on-black.png";
import CLogoOnWhite from "../assets/logo/c-logo-on-white.png";
import logoOnWhite from "../assets/logo/logo-on-white.png";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

const Wrapper = styled.div`
  img {
    width: 100%;
    height: auto;
  }
`;

type LogoProps = {
  onBackground?: "white" | "black" | "yellow";
};

export const Logo = ({ onBackground = "white" }: LogoProps): JSX.Element => {
  const navigate = useNavigate();

  let logoSrc = logoOnWhite;
  switch (onBackground) {
    case "yellow":
      logoSrc = logoOnYellow;
      break;
    case "black":
      logoSrc = logoOnBlack;
      break;
    default:
      logoSrc = logoOnWhite;
      break;
  }

  return (
    <Wrapper onClick={() => navigate("/")}>
      <img src={logoSrc} alt="Classrooms for Change Logo" />
    </Wrapper>
  );
};

export const CLogo = ({ onBackground = "white" }: LogoProps): JSX.Element => {
  const navigate = useNavigate();

  let logoSrc = logoOnWhite;
  switch (onBackground) {
    case "yellow":
      logoSrc = CLogoOnYellow;
      break;
    case "black":
      logoSrc = CLogoOnBlack;
      break;
    default:
      logoSrc = CLogoOnWhite;
      break;
  }

  return (
    <Wrapper style={{ maxWidth: "28px" }} onClick={() => navigate("/")}>
      <img src={logoSrc} alt="Classrooms for Change Logo" />
    </Wrapper>
  );
};
