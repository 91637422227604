import { useTranslation } from "react-i18next";

export function TimeSince(date: any) {
  const { t } = useTranslation();

  date = new Date(date);
  const newDate: any = new Date();
  var seconds = Math.floor((newDate - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + t("time.yearsAgo", { count: Math.floor(interval) });
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + t("time.monthsAgo", { count: Math.floor(interval) });
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + t("time.daysAgo", { count: Math.floor(interval) });
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + t("time.hoursAgo", { count: Math.floor(interval) });
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + t("time.minutesAgo", { count: Math.floor(interval) });
  }
  return Math.floor(seconds) + t("time.secondsAgo", { count: Math.floor(interval) });
}
