import { Box, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Text1, Title1, Title3, Title4, Title5 } from "../../components/UI/Text";
import { Card, Layout } from "../../components/UI/Common";
import Grid from "@mui/material/Unstable_Grid2";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import styled from "styled-components";
import { Container } from "@mui/system";
import { GetToKnow } from "./GetToKnow";
import { BlobButton } from "../../components/UI/BlobButton";
import { Link } from "react-router-dom";
import { Size } from "../../config/theme.config";
import { Logo } from "../../components/Logo";

export const Home = (): JSX.Element => {
  return (
    <Layout>
      <Stack sx={{ gap: { xs: 6, md: 8, lg: 10 } }}>
        <About />
        <WhoAreWe />
        <BuildingRequirements />
        <GetToKnow />
      </Stack>
    </Layout>
  );
};

const About = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="row" justifyContent="flex-start" sx={{ flexGrow: 1 }}>
      <Grid container spacing={{ xs: 4, sm: 6 }}>
        <Grid xs={12} md={8}>
          <Box>
            <Logo onBackground="yellow" />
          </Box>
          <Text1 marginTop={1}>{t("pages.home.about.section1.p1")}</Text1>
          <Title5 weight="bold">{t("pages.home.about.section2.subtitle")}</Title5>
          <Text1 sx={{ marginBottom: "0 !important" }}>{t("pages.home.about.section2.p1")}</Text1>
        </Grid>
        <LatestUpdateCard />
        <Grid xs={12}>
          <Title5 weight="bold">{t("pages.home.about.section3.subtitle")}</Title5>
          <Text1>{t("pages.home.about.section3.p1")}</Text1>
          <Text1>{t("pages.home.about.section4.p1")}</Text1>
          <Text1>{t("pages.home.about.section4.p2")}</Text1>
        </Grid>
        <Slogan />
      </Grid>
    </Box>
  );
};

const LatestUpdateCard = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Grid xs={12} md={4}>
      <Box display="flex" alignItems="flex-end" height="100%">
        <Card secondary>
          <Title4
            sx={{
              marginBottom: {
                xs: "16px !important",
                md: "24px !important",
              },
            }}
            weight="bold"
          >
            {t("pages.home.latestUpdateCard.title")}
          </Title4>
          <Text1
            sx={{
              marginBottom: {
                xs: "16px !important",
                md: "24px !important",
              },
            }}
          >
            {t("pages.home.latestUpdateCard.text")}
          </Text1>
          <Link to="/latest-updates">
            <BlobButton secondary text={t("pages.home.latestUpdateCard.button")} />
          </Link>
        </Card>
      </Box>
    </Grid>
  );
};
const Slogan = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <Grid sx={{ display: { xs: "none !important", md: "block !important" } }} sm={2.5}></Grid>
      <Grid xs={12} md={8}>
        <Title1
          dangerouslySetInnerHTML={{
            __html: t("pages.home.slogan", { interpolation: { escapeValue: false } }),
          }}
        ></Title1>
        <Text1
          sx={{
            marginBottom: {
              xs: "16px !important",
              md: "24px !important",
              xl: "32px !important",
            },
          }}
          mb={6}
        >
          {t("pages.home.intro")}
        </Text1>
        <Box>
          <a target="_blank" href="https://gofund.me/e8234334" rel="noreferrer">
            <BlobButton text={t("pages.home.donateButton")} />
          </a>
          {/* <Link to="/donate">
              <BlobButton text={t("pages.home.donateButton")} />
            </Link> */}
        </Box>
      </Grid>
    </>
  );
};
const WhoAreWe = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Box>
      <Container maxWidth="xl" sx={{ paddingInline: "0 !important" }}>
        <Card secondary>
          <Box
            px={6}
            sx={{
              paddingBlock: { xs: 0.5, sm: 1, md: 2, lg: 4 },
              paddingInline: { xs: 0.75, sm: 1.25, md: 2.5, lg: 6 },
              flexGrow: 1,
            }}
          >
            <Grid container spacing={2}>
              <Grid xs={12}>
                <Title3 weight="bold">{t("pages.home.whoAreWe.longTerm.title")}</Title3>
                <Text1
                  dangerouslySetInnerHTML={{
                    __html: t("pages.home.whoAreWe.longTerm.text", {
                      interpolation: { escapeValue: false },
                    }),
                  }}
                />
              </Grid>
              <Grid xs={12} sm={6}>
                <Left>
                  <Title3 weight="bold">{t("pages.home.whoAreWe.mission.title")}</Title3>
                  <Text1
                    dangerouslySetInnerHTML={{
                      __html: t("pages.home.whoAreWe.mission.text", {
                        interpolation: { escapeValue: false },
                      }),
                    }}
                  />
                </Left>
              </Grid>
              <Grid xs={12} sm={6}>
                <Right>
                  <Title3 weight="bold">{t("pages.home.whoAreWe.succeed.title")}</Title3>
                  <Text1
                    mb={0}
                    dangerouslySetInnerHTML={{
                      __html: t("pages.home.whoAreWe.succeed.text", {
                        interpolation: { escapeValue: false },
                      }),
                    }}
                  />
                </Right>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Container>
    </Box>
  );
};

const Left = styled.div`
  @media only screen and (min-width: ${Size.sm}px) {
    padding-right: ${(props) => props.theme.spacer * 1.5}px;
  }
`;
const Right = styled.div`
  padding-bottom: ${(props) => props.theme.spacer * 1}px;
  @media only screen and (min-width: ${Size.sm}px) {
    padding-left: ${(props) => props.theme.spacer * 4}px;
    border-left: 2px solid ${(props) => props.theme.colors.black};
  }
`;

const BuildingRequirements = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Box
      style={{ background: "#ffffff", borderRadius: 12, width: "100%" }}
      sx={{
        padding: {
          xs: "18px !important",
          md: "32px !important",
        },
        maxWidth: {
          xs: "500px",
          md: "100%",
        },
        margin: {
          xs: "0 auto",
          md: "initial",
        },
      }}
    >
      <Title3
        sx={{
          marginBottom: {
            xs: "8px !important",
            md: "24px !important",
          },
        }}
        weight="bold"
      >
        {t("pages.home.building.title")}
      </Title3>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={{ xs: 1, md: 2 }} columns={{ xs: 12 }}>
          <Grid xs={12} md={6} lg={5} sx={{ paddingBottom: { md: "inherit", xs: 0 } }}>
            {[...Array(10)].map((item, index) => (
              <Box
                key={index}
                display="flex"
                flexDirection="row"
                alignItems="flex-start"
                gap={1}
                marginBottom={0.75}
              >
                <AddCircleOutlineIcon
                  fontSize="small"
                  sx={{ marginTop: "3px", color: "#F0D608" }}
                />
                <Text1 mb={0}>{t(`pages.home.building.li${index + 1}`)}</Text1>
              </Box>
            ))}
          </Grid>
          <Grid xs={12} md={6} lg={6} sx={{ paddingTop: { md: "inherit", xs: 0 } }}>
            {[...Array(8)].map((item, index) => (
              <Box
                key={index}
                display="flex"
                flexDirection="row"
                alignItems="flex-start"
                gap={1}
                marginBottom={0.75}
              >
                <AddCircleOutlineIcon
                  fontSize="small"
                  sx={{ marginTop: "3px", color: "#F0D608" }}
                />
                <Text1 mb={0}>{t(`pages.home.building.li${index + 11}`)}</Text1>
              </Box>
            ))}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
